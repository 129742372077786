<template>
  <div class="app_con">
    <app-list
      :rules="rules"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'系统用户'"
    >
      <div class="search" slot="tool">
        <el-input
          placeholder="根据标题查询"
          class="searchInput"
          v-model="title"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchQuery.title = title"
          ></el-button>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      rules: {
        //规则
        username: [
          //账号
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [
          //密码
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        name: [
          //姓名
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
          phone: [
          {      //手机
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("手机号不能为空"));
              } else {
                if (!this.$test.mobile(value)) {
                  callback(new Error("手机号格式不正确"));
                } else {
                  callback();
                }
              }
            },
            required: true,
            trigger: "blur",
          },
        ],
        roleId: [
          //角色
          { required: true, message: "请选择角色", trigger: "blur" },
        ],
      },
      remote: api.common,
      apiName: "/user",
      title: "",
      searchQuery: { title: "" },
      props: [
        {
          label: "账号",
          prop: "username",
          align: "center",
        },

        {
          label: "姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "手机",
          prop: "phone",
          align: "center",
        },
        {
          label: "角色",
          prop: "roleDescription",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "账号",
          type: "input",
          prop: "username",
        },
        {
          label: "密码",
          type: "input",
          prop: "password",
        },

        {
          label: "姓名",
          prop: "name",
          type: "input",
        },
        {
          label: "手机",
          prop: "phone",
          type: "input",
        },
        {
          label: "角色",
          prop: "roleId",
          type: "select",
          selectData: [],
        },
      ],
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    async getRoleList() {
      await api.role.getList().then((res) => {
        let arr = res.data.data;
        arr.forEach((item) => {
          this.formProps[4].selectData.push({
            value: item.id,
            label: item.description,
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
